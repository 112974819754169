import React from 'react'
import { NavLink } from 'react-router-dom'

const Header = () => {
  return (
        <div className="header">
            <div className="logo">
                <li><NavLink className="logo topper" exact to="/">rob muscus</NavLink></li>
                <li><NavLink className="logo bottom" exact to="/">DJ | Disco Shows | Light & Sound</NavLink></li>
            </div>
            <div className="menu">
                <ul>
                    <li><NavLink className="menulink" exact to="/">home</NavLink></li>
                    <li><NavLink className="menulink" to="/rob">dj rob</NavLink></li>
                    <li><NavLink className="menulink" to="/shows">shows</NavLink></li>
                    <li><NavLink className="menulink" to="/muziek">muziek</NavLink></li>
                    <li><NavLink className="menulink" to="/impressies">impressies</NavLink></li>
                    <li><NavLink className="menulink" to="/contact">contact</NavLink></li>
                </ul>
            </div>
        </div>   

  )
}
export default Header