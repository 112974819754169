import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

export const ContactSlogan = () => {
  return (
    <Fragment>
      Meer{" "}
      <NavLink className='highlight' to='/contact'>
        {" "}
        informatie{" "}
      </NavLink>
      over de{" "}
      <NavLink className='highlight' to='/shows'>
        mogelijkheden
      </NavLink>
      ? Neem contact op met{" "}
      <NavLink className='highlight' to='/rob'>
        Rob
      </NavLink>
      !
    </Fragment>
  );
};

export const ContactBody = () => {
  document.title = "Muscus.nl | Contact";
  return (
    <div className='content'>
      <h1>Contact</h1>
      <p>
        Wilt u meer weten over de shows, muziek en uitgebreide mogelijkheden?
        Neem gerust <span className='highlight_text'>contact</span> op!
      </p>

      <p>
        Of vraag <NavLink to='/offerte'>hier</NavLink> uw persoonlijke{" "}
        <NavLink to='/offerte'>offerte</NavLink> aan.
      </p>

      <p>
        {" "}
        U kunt mij bereiken via de onderstaande gegevens. Vragen worden altijd
        zo snel mogelijk beantwoord. Indien u de voicemail krijgt, spreek deze
        in en ik bel u zo spoedig mogelijk terug.
      </p>
      <p>
        <b>Rob Muscus</b>
        <br />
      </p>
      <p>
        Telefoon: 06 - 27 201 452 <br />
        Email: <a href='mailto:rob&#64;muscus&#46;nl'>rob@muscus.nl</a>
        <br />
        Website: <NavLink to='/'>www.muscus.nl</NavLink>
      </p>
      <p>
        KvK: 17233767
        <br />
        BTW: NL001796259B53
      </p>
      <p>
        <b>Gaan jullie trouwen?</b> <br />
        Van harte gefeliciteerd met het voorgenomen huwelijk! Speciaal voor
        huwelijksfeesten voert <NavLink to='/rob'>DJ Rob</NavLink> het label van{" "}
        <a className='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        {". "}
        <br />
        <br />
        Hier kunnen jullie terecht voor alle mogelijkheden welke special voor
        buidsparen geboden worden. Lees hier alles over op de website van{" "}
        <a className='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        !
        <br />
        <br />
        <li>
          <a href='https://theweddingdj.nl/djs/tilburg/'>
            <img
              src='https://theweddingdj.nl/image/the-wedding-dj-logo.png'
              alt=''
            />
          </a>
        </li>
        <br />
      </p>
    </div>
  );
};
