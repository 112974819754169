import React, { Fragment } from "react";
import { FacebookProvider, Like } from "react-facebook";
import { NavLink } from "react-router-dom";
import Twitter from "../images/site/Links/twitter.png";
import Facebook from "../images/site/Links/facebook.png";
import Flickr from "../images/site/Links/flickr.png";

export const HomeSlogan = () => {
  return (
    <Fragment>
      <NavLink className='highlight' to='/rob'>
        DJ Rob
      </NavLink>{" "}
      verzorgt de{" "}
      <NavLink className='highlight' to='/muziek'>
        muziek
      </NavLink>{" "}
      en{" "}
      <NavLink className='highlight' to='/shows'>
        Drive-in Shows
      </NavLink>{" "}
      voor diverse feesten en partijen.
      <div className='frontpagelink'>
        <a
          href='https://facebook.com/DJRobMuscus'
          target='_blank'
          rel='noreferrer'
        >
          <img src={Facebook} alt='' />{" "}
        </a>
        <a
          href='https://twitter.com/DJRobMuscus'
          target='_blank'
          rel='noreferrer'
        >
          <img src={Twitter} alt='' />
        </a>
        <a
          href='https://flickr.com/photos/DJRobMuscus'
          target='_blank'
          rel='noreferrer'
        >
          <img src={Flickr} alt='' />
        </a>
      </div>
    </Fragment>
  );
};

export const HomeBody = () => {
  document.title = "Muscus.nl | Home";
  return (
    <div className='content'>
      <h1>Welkom bij DJ Rob Muscus</h1>
      <br />
      <p>
        DJ Rob Muscus verzorgt muziek en de Drive-in Shows voor diverse feesten
        en partijen. Neem gerust een kijkje op deze website waar{" "}
        <NavLink to='/rob'>DJ Rob</NavLink> voor staat. Voor een persoonlijk
        gesprek kunt u <NavLink to='/contact'>contact</NavLink> opnemen.
      </p>
      <p>
        <b>Gaan jullie trouwen?</b> <br />
        Van harte gefeliciteerd met het voorgenomen huwelijk! Speciaal voor
        huwelijksfeesten voert <NavLink to='/rob'>DJ Rob</NavLink> het label van{" "}
        <a className='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        {". "}
        <br />
        <br />
        Hier kunnen jullie terecht voor alle mogelijkheden welke special voor
        buidsparen geboden worden. Lees hier alles over op de website van{" "}
        <a className='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        !
        <br />
        <br />
        <li>
          <a href='https://theweddingdj.nl/djs/tilburg/'>
            <img
              src='https://theweddingdj.nl/image/the-wedding-dj-logo.png'
              alt=''
            />
          </a>
        </li>
        <br />
      </p>
      <FacebookProvider appId='287891899329294'>
        <Like
          to='https://www.facebook.com/DJRobMuscus'
          colorScheme='dark'
          showFaces={true}
          share={true}
        />
      </FacebookProvider>
    </div>
  );
};
