import React, { Fragment } from "react";

import { NavLink } from "react-router-dom";

export const PrijzenSlogan = () => {
  return (
    <Fragment>
      Online offerte nog niet beschikbaar. Neem{" "}
      <NavLink className='highlight' to='/contact'>
        contact
      </NavLink>{" "}
      op met{" "}
      <NavLink className='highlight' to='/rob'>
        Rob
      </NavLink>
    </Fragment>
  );
};

export const PrijzenBody = () => {
  document.title = "Muscus.nl | Under Construction";
  return (
    <div className='content'>
      <p>
        <h1>Samenstellen Offerte</h1> <br />
        <b>Gaan jullie trouwen?</b> <br />
        Van harte gefeliciteerd met het voorgenomen huwelijk! Speciaal voor
        huwelijksfeesten voert <NavLink to='/rob'>DJ Rob</NavLink> het label van{" "}
        <a classname='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        {". "}
        <br />
        <br />
        Hier kunnen jullie terecht voor alle mogelijkheden welke special voor
        buidsparen geboden worden. Lees hier alles over op de website van{" "}
        <a classname='highlight' href='https://theweddingdj.nl/djs/tilburg/'>
          The Wedding DJ
        </a>
        . Hier is het ook mogelijk om online een offerte aan te vragen.
        <br />
        <br />
        <li>
          <a href='https://theweddingdj.nl/djs/tilburg/'>
            <img
              src='https://theweddingdj.nl/image/the-wedding-dj-logo.png'
              alt=''
            />
          </a>
        </li>
      </p>{" "}
      <br />
      <b>Nog niet operationeel</b>
      <br /> <br />
      <p>
        Binnenkort zal hier de vernieuwde show met prijzen beschikbaar zijn.
      </p>
      <p>
        Neem gerust <NavLink to='/contact'>contact</NavLink> op met{" "}
        <NavLink to='/rob'> Rob </NavLink> voor meer informatie.
      </p>
    </div>
  );
};
