import React, { Component } from "react";
import CrossfadeImage from "react-crossfade-image";
import { withRouter, NavLink } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { connect } from "react-redux";
import DjRob from "../images/site/HomeSite/Rob1.jpg";
import Shows from "../images/site/HomeSite/P2234358.jpg";
import Muziek from "../images/site/HomeSite/PB252428.jpg";
import Impressies from "../images/site/HomeSite/bruiloft (418).jpg";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("../images/site/imgpoule/", false, /\.(png|jpe?g|svg)$/)
).map((image) => {
  return { path: image.default };
});

class Imgwrap extends Component {
  state = {
    imgNext: 0,
    images: images,
    Key: 0,
  };
  componentDidMount() {
    this.timerID = setInterval(
      () => this.tick(),
      5000 // 5s
    );
  }
  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    this.setState({
      imgNext: Math.floor(Math.random() * this.state.images.length),
    });
  }
  HomeActive() {
    if (this.props.location.pathname === "/") {
      this.props.updateFrontPage("bar_index");
      return (
        <React.Fragment key={456}>
          <div className='bar_content'>
            <div className='mosaiccontainer'>
              <div className='mosaic'>
                <div className='mosaicborder'>
                  <img className='mosaicpicture' src={DjRob} alt='' />
                  <NavLink className='mosaictext' to='/rob'>
                    DJ Rob
                  </NavLink>
                </div>
                <div className='mosaicborder'>
                  <img className='mosaicpicture' src={Shows} alt='' />
                  <NavLink className='mosaictext' to='/shows'>
                    Shows
                  </NavLink>
                </div>
              </div>
              <div className='mosaic'>
                <div className='mosaicborder'>
                  <img className='mosaicpicture' src={Muziek} alt='' />
                  <NavLink className='mosaictext' to='/muziek'>
                    Muziek
                  </NavLink>
                </div>
                <div className='mosaicborder'>
                  <img className='mosaicpicture' src={Impressies} alt='' />
                  <NavLink className='mosaictext' to='/impressies'>
                    Impressies
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      this.props.updateFrontPage("bar_page");
      return (
        <React.Fragment key={123}>
          <div className='imgwrap'>
            <div className='bar_right'>
              <CrossfadeImage
                src={this.state.images[this.state.imgNext].path}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <TransitionGroup>
          <CSSTransition
            key={this.HomeActive().key}
            timeout={300}
            classNames='fade'
          >
            {this.HomeActive()}
          </CSSTransition>
        </TransitionGroup>
      </React.Fragment>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateFrontPage: (Frontpage) => {
      dispatch({ type: "SET_FRONT", Frontpage: Frontpage });
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Imgwrap));
