import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

export const ShowsSlogan = () => {
  return (
    <Fragment>
      De moderne{" "}
      <NavLink className='highlight' to='/shows'>
        techniek
      </NavLink>{" "}
      zorgt voor een unieke{" "}
      <NavLink className='highlight' to='/impressies'>
        beleving
      </NavLink>{" "}
      van{" "}
      <NavLink className='highlight' to='/muziek'>
        muziek
      </NavLink>
      .
    </Fragment>
  );
};

export const ShowsBody = () => {
  document.title = "Muscus.nl | Shows";
  return (
    <div className='content'>
      <h1>Drive-in Shows</h1>
      <p></p>
      <b>Geluid</b>
      <br />
      <p>
        De geluidsinstallatie bestaat onder andere uit 2 actieve RCF ART422A
        mkII luidsprekers. Deze klinken strak en helder, ook op hardere
        geluidsvolumes. Deze 2 speakers zijn voldoende voor een gezelschap tot
        maximaal 50 personen. Voor stevigere muziek en een gezelschap groter dan
        50 personen raden wij u aan om 1 of 2 extra basspeakers bij te plaatsen.
        Dit zijn actieve 18 inch RCF 4PRO8002 luidsprekers. Deze verzorgen een
        warm, stevig laag en sluiten perfect aan op de top luidsprekers. Het
        totale vermogen is 2400 watt RMS, voldoende voor 200 personen. Een
        grotere set is ook mogelijk, neem dan{" "}
        <NavLink to='/contact'>contact</NavLink> op voor een offerte.{" "}
      </p>

      <b>Muziek</b>
      <br />
      <p>
        De muziek wordt afgespeeld vanaf een laptop. Deze wordt bediend met een
        professioneel midi DJ paneel om strakke mixen te kunnen maken. Door het
        gebruik van een computer is de kans zeer groot dat uw verzoekje hier
        tussen staat. En het is ook mogelijk om deze snel over de geluidsset te
        laten horen. Er gaat geen tijd meer verloren in het zoeken naar de
        juiste cd.
      </p>

      <b>Basis verlichting</b>
      <br />
      <p>
        Veel locaties hebben tegenwoordig zelf basisverlichting al aangebracht.
        Mocht dit niet het geval zijn kunnen wij deze verzorgen De basis
        verlichting bestaat vaak uit 1 of 2 balken elk voorzien van 4 multicolor
        led spots. Standaard wordt er een wit DJ meubel geplaatst met multicolor
        verlichte vakken. Hiermee krijgt u een representatieve strakke
        uitstraling. Door het gebruik van de juiste lichtsturing software zijn
        speciale wensen voor kleuren makkelijk te implementeren in de show. Het
        is bijvoorbeeld mogelijk om de vakken van het meubel standaard rood,
        paars of een andere kleur te geven. Er is ook de mogelijkheid om
        vloerspots te plaatsen waarbij draadloos de locatie uitgelicht kan
        worden. Dit verhoogd de sfeer en betrokkenheid enorm.
      </p>

      <b>Effect verlichting</b>
      <br />
      <p>
        Wij maken gebruik van zeer proffesionele movingheads. Dit zijn bewegende
        lampen die verschillende figuren, kleuren en effecten kunnen projecteren
        door de zaal. Indien de locatie het toelaat kunnen wij een hazer
        plaatsen waardoor er een proffesionele uitstraling ontstaat. De
        lichtbundels zijn dan te zien door de zaal heen zonder dat er een
        rookwolk ontstaat zoals het bij rookmachines het geval is.
        <br /> <br />
        Het is ook mogelijk diverse andere effecten bij te boeken zoals
        sparkulars (koudvuur fontein) en confettie.
      </p>

      <b>Offerte aanvragen</b>
      <br />
      <p>
        Klik <NavLink to='/offerte'>hier</NavLink> om een{" "}
        <NavLink to='/offerte'>offerte</NavLink> aan te vragen.
      </p>
      <p>
        {" "}
        Heeft u vragen of speciale wensen neem dan vrijblijvend{" "}
        <NavLink to='/contact'>contact</NavLink> op!
      </p>

      <p>
        Bekijk <NavLink to='/impressies'>hier</NavLink> de{" "}
        <NavLink to='/impressies'>impressies</NavLink> van onze shows'
      </p>
    </div>
  );
};
