import React, { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

class MainImgErv extends Component {
  state = {
    Ervaringen: [
      {
        Id: 1,
        Naam: "M. Thomas",
        Ervaring: "Rob wist er een mooi feest van te maken.",
      },
      {
        Id: 2,
        Naam: "M. Stadhouders",
        Ervaring: "Rob wist precies wat te draaien en voelde het publiek aan.",
      },
      {
        Id: 3,
        Naam: "J. van Doorn",
        Ervaring:
          "Het was een geweldig feest....... dankjewel voor je mooie show!!!",
      },
      {
        Id: 4,
        Naam: "E. Smit",
        Ervaring: "De volgende keer vraag ik Rob weer!",
      },
      {
        Id: 5,
        Naam: "O. Scholz",
        Ervaring: "De buurman was onder de indruk, hij gaat je ook vragen!",
      },
      {
        Id: 6,
        Naam: "S. Donders",
        Ervaring:
          "Hij wist ook de oudere nummers te vinden, dat zie je niet vaak meer tegenwoordig!",
      },
      {
        Id: 7,
        Naam: "B. Geerts",
        Ervaring:
          "Ik vroeg stevige muziek, ik kreeg stevige muziek. Het was een feest om nooit meer te vergeten!",
      },
      {
        Id: 8,
        Naam: "Thomas en Janneke",
        Ervaring:
          "Wat een feest. Ons festivalstijltje werd goed begrepen en dj Rob was ook zichtbaar aan het genieten.",
      },
      {
        Id: 9,
        Naam: "Ad en Lidy",
        Ervaring:
          "Rob voelde het aanwezige gezelschap goed aan en draaide de muziek die wij en hij mooi vonden voor deze avond. ",
      },
      {
        Id: 10,
        Naam: "Bregje en Florian",
        Ervaring:
          "De dansvloer was voortdurend drukbezet en we ons geen moment druk hoeven maken of Rob begreep wat er nodig was of wat we wilden.",
      },
      {
        Id: 11,
        Naam: "Jasper",
        Ervaring:
          "Rob heeft een mooi feest gemaakt van onze bruiloft. Als oud DJ wilde ik niet alleen maar de geijkte bruiloft hitjes horen.",
      },
      {
        Id: 12,
        Naam: "Maurice en Siska",
        Ervaring:
          "Top avond gehad! Feestje ging vanaf begin meteen los en niet meer gestopt. Super gezellig.",
      },
      {
        Id: 13,
        Naam: "Amy",
        Ervaring:
          "Het feest was helemaal top! Mooie opzet, veel lichten, nummers allemaal ingespeeld op de reacties van de gasten... wauw!",
      },
    ],
    ShowReviews: [],
    ReviewToModify: 0,
  };
  SetPassReview = (ShowedReviews) => {
    // check if showed reviews already contain this ID, or  randomize again
    let AvailableReviews = this.state.Ervaringen;
    let ReviewToPass;
    if (Array.isArray(ShowedReviews) && ShowedReviews.length) {
      ReviewToPass = Math.floor(Math.random() * AvailableReviews.length);

      while (
        // eslint-disable-next-line
        ShowedReviews.some((e) => e.Id === AvailableReviews[ReviewToPass].Id)
      ) {
        ReviewToPass = Math.floor(Math.random() * AvailableReviews.length);
      }
    } else {
      ReviewToPass = Math.floor(Math.random() * AvailableReviews.length);
    }
    return AvailableReviews[ReviewToPass];
  };
  SetInitialReviews = () => {
    let InitShowedReviews = [];
    for (var i = 0; i < 3; i++) {
      let ObjectToAdd = this.SetPassReview(InitShowedReviews);
      InitShowedReviews.push(ObjectToAdd);
    }
    this.setState({
      ShowReviews: InitShowedReviews,
    });
  };

  componentDidMount() {
    this.SetInitialReviews();
    this.timerID = setInterval(
      () => this.tick(),
      5000 // 5s
    );
  }

  tick() {
    let ReviewToModify = this.state.ReviewToModify;
    let CurrentReviews = this.state.ShowReviews;
    CurrentReviews[ReviewToModify] = this.SetPassReview(CurrentReviews);
    if (ReviewToModify < 2) {
      ReviewToModify++;
    } else {
      ReviewToModify = 0;
    }
    this.setState({
      ShowReviews: CurrentReviews,
      ReviewToModify: ReviewToModify,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    const ShowReviewList = this.state.ShowReviews.map((review) => {
      return (
        <TransitionGroup>
          <CSSTransition
            // fadeappear={true}
            key={review.Id}
            timeout={300}
            classNames='fade'
          >
            <p className='ervaring_text'>
              {review.Ervaring}
              <br />
              <span className='ervaring_naam'>{"~ " + review.Naam}</span>
            </p>
          </CSSTransition>
        </TransitionGroup>
      );
    });

    return (
      <React.Fragment>
        <div className='main-ervaringen'>{ShowReviewList}</div>
      </React.Fragment>
    );
  }
}
export default MainImgErv;
