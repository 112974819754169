import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

export const MuziekSlogan = () => {
  return (
    <Fragment>
      Door{" "}
      <NavLink className='highlight' to='/contact'>
        persoonlijk
      </NavLink>{" "}
      contact de perfecte{" "}
      <NavLink className='highlight' to='/muziek'>
        muziek
      </NavLink>{" "}
      voor uw feest of evenement.
    </Fragment>
  );
};

export const MuziekBody = () => {
  document.title = "Muscus.nl | Muziek";
  return (
    <div className='content'>
      <h1>Muziek</h1>
      <p></p>
      <b> Welke muziek er afgespeeld wordt? Zegt u het maar!</b>

      <p>
        Door de vele jaren ervaring bent u er van verzekerd dat u goede
        invulling krijgt van het feest of evenement waar u{" "}
        <NavLink to='/rob'>Rob</NavLink> voor inhuurt. Uw wensen worden altijd
        besproken in een persoonlijk gesprek, dit kan indien gewenst per
        telefoon, bij u thuis of op locatie. Alle gangbare muziek is aanwezig en
        speciale verzoeken zijn geen probleem. Tevens zal{" "}
        <NavLink to='/rob'>Rob</NavLink> u advies geven over hoe de muziekkeuze
        er uit kan zien voor een geslaagde avond. Ver voor het feest is bekend
        waar u aan toe bent, u hoeft niet te wachten tot de DJ geselecteerd is.
        Rob zal u adviseren en begeleiden in het maken van de juiste keuzes voor
        een geslaagde avond!
      </p>

      <b>Uw feest, uw muziek</b>
      <br />
      <p>
        Wilt u graag jaren 70, 80 of toch net het hippe uit de top 40, misschien
        de stevige beats van de hedendaagse house. Alles is mogelijk! Wat ook
        besproken wordt met u, dit wordt vaak vergeten, is de muziek die u juist
        niet wilt horen op een feest. Het kunnen nummers zijn met een emotionele
        lading of het is simpelweg uw smaak niet. Ook kunt u aangeven of
        verzoekjes toegestaan zijn en hoe deze behandeld gaan worden. Mogen deze
        direct gespeeld worden of liever inpassen in de sfeer van de avond.
      </p>

      <b>Flexibel</b>
      <br />
      <p>
        De muziek kan aangepast worden aan het moment en sfeer op het feest of
        evenement. Bij binnenkomst een rustig achtergrond muziekje en op het
        hoogtepunt de juiste knallers om de sfeer er om te houden. Vaak gaat een
        feestavond ook gepaard met een opbouwende sfeer. Door de juiste
        muziekkeuze wordt er een opzwepend effect bereikt waardoor de{" "}
        <NavLink to='/impressies'>voetjes van de vloer</NavLink> gaan.
      </p>

      <b>Tijdens de avond</b>
      <br />
      <p>
        De uitgebreide muziekcollectie met onder andere top 40, house, apres-ski
        en goude oude wordt afgespeeld via de{" "}
        <NavLink to='/shows'>computer</NavLink> waardoor verzoekjes zeer snel
        ingezet kunnen worden. Ook geeft dit de mogelijkheid om vooraf een lijst
        samen te stellen met de nummers die u graag wilt laten draaien. Denk
        hierbij bijvoorbeeld aan de openingsdans en afsluiter voor een bruiloft.
        Er is ook de mogelijkheid eigen (MP3) cd's of USB sticks mee te brengen.
      </p>

      <p>
        Tevens is Rob te huur als losse dj. Neem{" "}
        <NavLink to='/contact'>contact</NavLink> op voor de tarieven en
        mogelijkheden.
      </p>
    </div>
  );
};
