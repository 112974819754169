import React, { Fragment } from "react";
import ImageGallery from "react-image-gallery";
import { NavLink } from "react-router-dom";

export const ImpressiesSlogan = () => {
  return (
    <Fragment>
      De gezellige{" "}
      <NavLink className='highlight' to='/impressies'>
        sfeer
      </NavLink>{" "}
      wordt bepaald door{" "}
      <NavLink className='highlight' to='/muziek'>
        muziek
      </NavLink>{" "}
      speciaal voor uw{" "}
      <NavLink className='highlight' to='/impressies'>
        gasten
      </NavLink>
      .
    </Fragment>
  );
};

export const ImpressiesBody = () => {
  document.title = "Muscus.nl | Impressies";

  function importAll(r) {
    return r.keys().map(r);
  }

  const images = importAll(
    require.context("../images/site/Carousel/", false, /\.(png|jpe?g|svg|JPG)$/)
  ).map((image) => {
    return { original: image.default, thumbnail: image.default };
  });

  return (
    <div className='content'>
      <h1>Impressies</h1> <br />
      <p>
        Hieronder vind u enkele sfeer foto's van onze{" "}
        <NavLink to='/shows'>shows</NavLink>. Klik{" "}
        <NavLink to='/shows'>hier</NavLink> voor meer informatie over de
        gebruikte <NavLink to='/shows'>techniek</NavLink>. Mocht u vragen hebben
        neem gerust <NavLink to='/contact'>contact</NavLink> op of vraag direct
        een <NavLink to='/offerte'>offerte</NavLink> aan!
      </p>{" "}
      <p />
      <ImageGallery
        width='400px'
        autoPlay={true}
        items={images}
        lazyLoad={true}
      />
    </div>
  );
};
