import React from "react";
import { NavLink } from "react-router-dom";
// import { navbar }  from 'react-bootstrap'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footerContent'>
        <div className='footerColumn'>
          <h3>
            <NavLink className='FooterTopLink' to='/contact'>
              bedrijfsgegevens
            </NavLink>
          </h3>
          <p>
            Telefoon: 06 - 27 201 452
            <br />
            Telefoon: 06 - 27 201 452
            <br />
            KvK: 17233767
            <br />
            BTW: NL001796259B53
          </p>
        </div>
        <div className='footerColumn'>
          <h3>
            <NavLink className='FooterTopLink' to='/rob'>
              dj rob
            </NavLink>
          </h3>
          <p>
            Facebook:{" "}
            <a href='https://facebook.com/DJRobMuscus'>DJ Rob Muscus</a>
            <br />
            Twitter: <a href='https://twitter.com/DJRobMuscus'>@DJRobMuscus</a>
            <br />
            Flickr:{" "}
            <a href='https://flickr.com/photos/DJRobMuscus'>DJRobMuscus</a>
            <br />
          </p>
        </div>
        <div className='footerColumn'>
          <h3>
            <NavLink className='FooterTopLink' to='/shows'>
              shows
            </NavLink>
          </h3>
          <p>
            Show: <NavLink to='/offerte/show/klein'>Klein</NavLink>
            <br />
            Show: <NavLink to='/offerte/show/middel'>Middel</NavLink>
            <br />
            Show: <NavLink to='/offerte/show/groot'>Groot</NavLink>
            <br />
            <NavLink to='/AVROBMUSCUS.pdf'>Algemene Voorwaarden</NavLink>
            <br />
          </p>
        </div>
        <div className='footerColumn'>
          <h3>
            <NavLink className='FooterTopLink' to='/muziek'>
              muziek
            </NavLink>
          </h3>
          <p>
            Muziek: <NavLink to='/muziek'>Top 40</NavLink>
            <br />
            Muziek: <NavLink to='/muziek'>Goude Oude</NavLink>
            <br />
            Muziek: <NavLink to='/muziek'>Dance / House</NavLink>
            <br />
            <NavLink to='/contact'>Uw persoonlijke keuze!</NavLink>
            <br />
          </p>
        </div>
        <div className='footerColumn'>
          <h3>
            <NavLink className='FooterTopLink' to='/impressies'>
              impressies
            </NavLink>
          </h3>
          <p>
            <NavLink to='/impressies'>Foto's</NavLink>
            <br />
            <NavLink to='/impressies'>Sfeer</NavLink>
            <br />
            <NavLink to='/impressies'>Techniek</NavLink>
            <br />
            <NavLink to='/contact'>Referenties</NavLink>
            <br />
          </p>
        </div>
        <div className='clear'></div>
      </div>
    </div>
  );
};

export default Footer;
