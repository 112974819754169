import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { RobBody, RobSlogan } from "./pages/Rob";
import { MuziekBody, MuziekSlogan } from "./pages/Muziek";
import { HomeBody, HomeSlogan } from "./pages/Home";
import { ContactBody, ContactSlogan } from "./pages/Contact";
import { ShowsBody, ShowsSlogan } from "./pages/Shows";
import { ImpressiesBody, ImpressiesSlogan } from "./pages/Impressies";
import { PageNotFoundBody, PageNotFoundSlogan } from "./pages/PageNotFound";
import { PrijzenBody, PrijzenSlogan } from "./pages/Prijzen";
import Footer from "./partials/footer";
import Header from "./partials/header";
import Imgwrap from "./partials/imgwrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import MainImgErv from "./partials/MainImgErv";
import { connect } from "react-redux";

//Todo:
// - Thumbnails maken en laden in impressies
// - links op de home pagina (twitter facebook en insta) in orde maken
// - overlay maken voor kiezen muscus.nl of linken naar www.theweddingdj.nl/tilburg
// - offerte module maken met shows en add-ons
// - contact formulier maken
// - Facebook pixel implementeren
// - google analytics implementeren
// - cookie consensus maken
// - foto's wisselen op home pagina?

// import overlay from './pages/homeoverlay'
class App extends Component {
  render() {
    return (
      <React.Fragment>
        {/* <overlay /> */}
        <BrowserRouter>
          <div className='wrapper'>
            <Imgwrap />
            <Header />
            <div className={this.props.Frontpage}>
              <div className='bar_content'>
                <div className='bar_left'>
                  <h1>
                    <Route>
                      <Switch>
                        <Route exact path='/' component={HomeSlogan} />
                        <Route path='/rob' component={RobSlogan} />
                        <Route path='/muziek' component={MuziekSlogan} />
                        <Route path='/shows' component={ShowsSlogan} />
                        <Route path='/contact' component={ContactSlogan} />
                        <Route path='/offerte' component={PrijzenSlogan} />

                        <Route
                          path='/impressies'
                          component={ImpressiesSlogan}
                        />
                        {/* <Route component={PageNotFoundSlogan} /> */}
                      </Switch>
                    </Route>
                  </h1>
                </div>
              </div>
            </div>

            <div className='main'>
              {this.props.Frontpage !== "bar_index" ? (
                <div className='main-imgvisibele' />
              ) : (
                <div className='main-imghidden' />
              )}
              <MainImgErv />
              <Route
                render={({ location }) => {
                  return (
                    <TransitionGroup>
                      <CSSTransition
                        key={location.key + "2"}
                        timeout={300}
                        classNames='fade'
                      >
                        <Switch location={location}>
                          <Route exact path='/' component={HomeBody} />
                          <Route path='/rob' component={RobBody} />
                          <Route path='/muziek' component={MuziekBody} />
                          <Route path='/shows' component={ShowsBody} />
                          <Route path='/contact' component={ContactBody} />
                          <Route path='/offerte' component={PrijzenBody} />
                          <Route
                            path='/impressies'
                            component={ImpressiesBody}
                          />
                          {/* <Route component={PageNotFoundBody} /> */}
                        </Switch>
                      </CSSTransition>
                    </TransitionGroup>
                  );
                }}
              />
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Frontpage: state.Frontpage,
  };
};
export default connect(mapStateToProps)(App);
