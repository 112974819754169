import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";

export const RobSlogan = () => {
  return (
    <Fragment>
      Jong begonnen met liefde voor{" "}
      <NavLink className='highlight' to='/muziek'>
        muziek
      </NavLink>{" "}
      en{" "}
      <NavLink className='highlight' to='/shows'>
        techniek
      </NavLink>
      .
    </Fragment>
  );
};

export const RobBody = () => {
  document.title = "Muscus.nl | DJ Rob";
  return (
    <div className='content'>
      <h1>DJ Rob</h1>
      <p></p>
      <b>Jong gedaan</b>
      <br />
      <p>
        Al in de vroege jaren was muziek het belangrijkste in zijn leven. Op
        jonge leeftijd met cassettedecks in de weer om de nieuwste top 40
        nummers af te spelen. Uiteindelijk kwam de CD en ging hij met zijn
        stereo op pad om de leukste feestjes te bouwen.
      </p>

      <b>Drive-in Show</b>
      <br />
      <p>
        Toch kon het beter en zo is de eerste{" "}
        <NavLink to='/shows'>Drive-in Show</NavLink> geboren. Van de eerste
        spaarcenten werd professionele audio- en lichtapparatuur aangeschaft,
        waardoor het een serieuze hobby werd. Deze{" "}
        <NavLink to='/shows'>show</NavLink> is van feest naar feest gesleept. Na
        een aantal jaren vond er een verschuiving in de soort aanvragen plaats.
        Rob werd veel vaker zonder show ingehuurd door diverse professionele
        bedrijven. Zij stuurden hem met een show van het bedrijf op pad naar een
        andere klant. Hierdoor is de "oude" show verkocht en bleef Rob alleen{" "}
        <NavLink to='/muziek'>muziek</NavLink> draaien met shows van
        opdrachtgevers. Dit had tot gevolg dat het lastig was om de particuliere
        en directe zakelijke klanten van een <NavLink to='/shows'>show</NavLink>{" "}
        te voorzien. Deze klanten werden vaak doorgestuurd naar de bedrijven die
        Rob inhuurden met een hogere prijs tot gevolg.
      </p>

      <b>Passie voor techniek</b>
      <br />
      <p>
        Ook de technische kant bleef hierdoor onbenut. De{" "}
        <NavLink to='/shows'>techniek</NavLink> die bij dergelijke shows komt
        kijken is redelijk complex. Denk hierbij aan lichtsturingen, vermogens
        en compatabiliteit van diverse systemen. Door de genoten technische
        opleiding blijft de interesse voor showtechniek bestaan waardoor hij
        zelf creatieve oplossingen kan bedenken en de set zelf van correct
        onderhoud voorziet.
      </p>

      <b>De nieuwe Drive-in Show</b>
      <br />
      <p>
        Sinds 2008 ontvangt Rob regelmatig aanvragen om ook een{" "}
        <NavLink to='/shows'>Drive-in Show</NavLink> te regelen, daarom is er
        nieuwe professionele audio- en lichtapparatuur aangeschaft. Met de
        ervaring die is opgedaan van de oude show en de diverse shows van
        opdrachtgevers heeft hij een uitgekiende keuze gemaakt tussen prijs,
        kwaliteit en betrouwbaarheid.
      </p>

      <p>
        Kijk bij de <NavLink to='/shows'>shows</NavLink> welke mogelijkheden er
        zijn of neem <NavLink to='/contact'>contact</NavLink> op voor
        vrijblijvende informatie.
      </p>
    </div>
  );
};
