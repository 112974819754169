const initState = {
  Frontpage: "",
};

const rootReducer = (state = initState, action) => {
  if (action.type === "SET_FRONT") {
    return {
      ...state,
      Frontpage: action.Frontpage,
    };
  }
  // console.log(state)
  return state;
};

// const setSlogan = { type: 'SET_SLOGAN', Slogan: 'De gezellige <span className="highlight"><a href="/impressies">sfeer</a></span> wordt bepaald door <span className="highlight"><a href="/muziek">muziek</a></span> speciaal voor uw <span className="highlight"><a href="/impressies">gasten</a></span>.'};

export default rootReducer;
